import Card                                      from 'antd/lib/card';
import { ColumnsType }                           from 'antd/lib/table/interface';
import { Page }                                  from 'components/Page';
import ContractFilter                            from 'components/filters/ContractFilter';
import ContractIterationContractActivityCategory from 'entityComponents/ContrractIteration/ContractIterationContractActivityCategory';
import { observer }                              from 'mobx-react';
import moment                                    from 'moment';
import React                                     from 'react';
import { formatPrice }                           from 'tools/PriceHelper';
import TablePaginationCollection                 from '../../components/TablePaginationCollection';
import ContractIterationNumberLink               from '../../entityComponents/ContrractIteration/ContractIterationNumber';
import ContractIterationStatusCircle             from '../../entityComponents/ContrractIteration/ContractIterationStatusCircle';
import ContractIterationModel                    from '../../modelx/models/private/sales/ContractIterationModel';
import {
	appStore,
	contractsStore,
}                                                from '../../stores';

const columns: ColumnsType<ContractIterationModel> = [
	{
		key: 'contractIterationStatus',
		render: (_v, contractIteration) => <ContractIterationStatusCircle
			contractIteration={contractIteration}
			contractIterationStatusCollection={appStore.contractIterationStatusCollection}
		/>,
		sorter: true,
		title: 'État',
	},
	{
		key: 'number',
		render: (_v, contractIteration) => <ContractIterationNumberLink
			contractCollection={contractsStore.contractCollection}
			contractIteration={contractIteration}
		/>,
		sorter: true,
		title: 'Numéro',
	},
	{
		key: 'title',
		render: (_v, contractIteration) => contractIteration.title,
		title: 'Titre',
	},
	{
		key: 'contract.activityCategory',
		render: (_v, contractIteration) => <ContractIterationContractActivityCategory
			activityCategoryCollection={contractsStore.activityCategoryCollection}
			contractCollection={contractsStore.contractCollection}
			contractIteration={contractIteration}
		/>,
		title: 'Type',
	},
	{
		key: 'totalPriceExclTax',
		render: (_v, contractIteration) => formatPrice(contractIteration.totalPriceExclTax),
		title: 'Montant total HT',
	},
	{
		key: 'startDate',
		render: (_v, contractIteration) => moment(contractIteration.startDate).format('L'),
		sorter: true,
		title: 'Début',
	},
	{
		key: 'endDate',
		render: (_v, contractIteration) => moment(contractIteration.endDate).format('L'),
		sorter: true,
		title: 'Fin',
	},
];

function MyContractListPage() {
	return (
		<Page title="Mes contrats">
			<div>
				<ContractFilter />
				<Card className="card-with-table" title="Synthèse de mes contrats">
					<TablePaginationCollection
						collection={contractsStore.contractIterationCollection}
						columns={columns}
						rowKey={contractIteration => contractIteration.id}
					/>
				</Card>
			</div>
		</Page>
	);
}

export default observer(MyContractListPage);