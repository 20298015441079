import Card                    from 'antd/lib/card';
import { Page }                from 'components/Page';
import React                   from 'react';
import { authenticationStore } from 'stores/index';
import ConfigProxy             from 'tools/ConfigProxy';
import { getUrlParams }        from 'tools/urlParamsTools';
import LoginImpersonateForm    from '../../forms/LoginImpersonateForm';
import PublicLayout            from '../../projectComponents/PublicLayout';

class LoginImpersonatePage extends React.Component {

	public componentDidMount() {
		authenticationStore.logout();
	}

	public render() {
		let title = '';
		switch (ConfigProxy.get('PARTITION_URN')) {
			case '$:partition:partition:1':
				title = 'Qualité - Hygiène - Services';
				break;
			case '$:partition:partition:2':
				title = 'DICOP';
				break;
			case '$:partition:partition:3':
				title = 'HPR';
				break;
			case '$:partition:partition:4':
				title = 'HPR Aquitaine';
				break;
			case '$:partition:partition:5':
				title = 'CG2D';
				break;
			case '$:partition:partition:6':
				title = 'RControlClim';
				break;
			case '$:partition:partition:7':
				title = 'IGIENAIR';
				break;
			case '$:partition:partition:8':
				title = 'ADI AMS';
				break;
			case '$:partition:partition:9':
				title = 'AIR SN';
				break;
			case '$:partition:partition:10':
				title = 'MAP CLIM';
				break;
			case '$:partition:partition:11':
				title = 'D2H';
				break;
			case '$:partition:partition:12':
				title = 'ZAACK';
				break;
			case '$:partition:partition:13':
				title = 'NANOSENSE';
				break;
			default:
			// require('./AntdCustomTheme.less');
		}

		const urlParams = getUrlParams();

		return (
			<PublicLayout title={title}>
				<Page title="Connexion">
					<Card>
						<div
							style={{
								marginBottom: 20,
								textAlign: 'center',
							}}
						>
							Connectez-vous avec vos accès administrateur pour continuer
						</div>
						<LoginImpersonateForm
							contactUrn={urlParams.contactUrn}
						/>
					</Card>

					{/*<Link*/}
					{/*	style={{ marginTop: 30, textAlign: 'center' }}*/}
					{/*	to="/forgotten-password"*/}
					{/*>*/}
					{/*	Mot de passe oublié ?*/}
					{/*</Link>*/}
				</Page>
			</PublicLayout>
		);
	}
}

export default LoginImpersonatePage;
