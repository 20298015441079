import { Page }                 from 'components/Page';
import React                    from 'react';
import { interventionsStore }   from 'stores/index';
import AbstractInterventionList from '../../../entityComponents/Intervention/AbstractInterventionList';

export default function InterventionListFuturePage() {
	return (
		<Page title="Prochaines interventions">
			<AbstractInterventionList
				cardProps={{
					title: 'Prochaines interventions',
				}}
				collection={interventionsStore.nextInterventionCollection}
				customColumns={(defaultColumns => [
					...defaultColumns,
					{
						align: 'right',
						fixed: 'right',
						render: () => null,
						title: '',
					}
				])}
			/>
		</Page>
	);
}
