import React, { useEffect } from 'react';

interface PageProps {
	children?: React.ReactNode;
	title: string;
}

export const Page: React.FC<PageProps> = props => {
	useEffect(() => {
		document.title = props.title;
	}, [props.title]);

	return (
		<>
			{props.children}
		</>
	);
};