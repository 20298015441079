import * as Sentry                                from '@sentry/browser';
import ContactModel                               from 'Models/directory/ContactModel';
import { reaction }                               from 'mobx';
import FrameworkAgreementIterationDashboardStore  from 'stores/FrameworkAgreementIterationDashboardStore';
import FrameworkAgreementAgreementIterationsStore from 'stores/FrameworkAgreementIterationsStore';
import { getIdFromUrn }                           from 'tools/UrnTools';
import notificationApiError                       from '../utils/notificationApiError';
import AppStore                                  from './AppStore';
import AuthenticationStore, { loggedConnectors } from './AuthenticationStore';
import ContactsStore                             from './ContactsStore';
import ContractDashboardStore                     from './ContractDashboardStore';
import ContractsStore                             from './ContractsStore';
import FunctionsStore                             from './FunctionsStore';
import GlobalFilterStore                          from './GlobalFilterStore';
import HomeStore                                  from './HomeStore';
import InterventionsStore                         from './InterventionsStore';
import InvoicesStore                              from './InvoicesStore';
import QuotationDashboardStore                    from './QuotationDashboardStore';
import QuotationsStore                            from './QuotationsStore';

export const appStore = new AppStore();
export const authenticationStore = new AuthenticationStore();
export const contactsStore = new ContactsStore();
export const contractDashboardStore = new ContractDashboardStore();
export const contractsStore = new ContractsStore();
export const frameworkAgreementIterationDashboardStore = new FrameworkAgreementIterationDashboardStore();
export const frameworkAgreementIterationsStore = new FrameworkAgreementAgreementIterationsStore();
export const functionsStore = new FunctionsStore();
export const globalFiltersStore = new GlobalFilterStore();
export const homeStore = new HomeStore();
export const interventionsStore = new InterventionsStore();
export const invoicesStore = new InvoicesStore();
export const quotationDashboardStore = new QuotationDashboardStore();
export const quotationsStore = new QuotationsStore();

const stores = {
	appStore,
	authenticationStore,
	contactsStore,
	contractDashboardStore,
	contractsStore,
	frameworkAgreementIterationDashboardStore,
	frameworkAgreementIterationsStore,
	functionsStore,
	globalFiltersStore,
	homeStore,
	interventionsStore,
	invoicesStore,
	quotationDashboardStore,
	quotationsStore,
};

export const clearStores = () => Object.values(stores).forEach(store => store.clear());

const applyContractIterationStatusUrnFilter = () => Object.values(stores).forEach((store: Store) => {
	if (store.setContractIterationStatusUrnFilterAndFetch && appStore.contractIterationStatusUrnFilter) {
		store.setContractIterationStatusUrnFilterAndFetch(appStore.contractIterationStatusUrnFilter);
	}
});

const applyQuotationStatusUrnFilter = () => Object.values(stores).forEach((store: Store) => {
	if (store.setQuotationStatusUrnFilterAndFetch && appStore.quotationStatusUrnFilter) {
		store.setQuotationStatusUrnFilterAndFetch(appStore.quotationStatusUrnFilter);
	}
});

const applyContractUrnFilter = () => Object.values(stores).forEach((store: Store) => {
	if (store.setUrnFilterAndFetch && appStore.contractUrnFilter) {
		store.setUrnFilterAndFetch(appStore.contractUrnFilter);
	}
});

const applyContactSearchFilter = () => Object.values(stores).forEach((store: Store) => {
	if (store.setContactSearchFilterAndFetch && appStore.contactSearchFilter) {
		store.setContactSearchFilterAndFetch(appStore.contactSearchFilter);
	}
});

const applyClientPartitionFilter = () => Object.values(stores).forEach((store: Store) => {
	if (store.setClientPartitionFilterAndFetch && appStore.selectedClientPartition) {
		store.setClientPartitionFilterAndFetch(appStore.selectedClientPartition);
	}
});

const applyTaskZoneFilter = () => Object.values(stores).forEach((store: Store) => {
	if (store.setTaskZoneFilterAndFetch && appStore.selectedTaskZone) {
		store.setTaskZoneFilterAndFetch(appStore.selectedTaskZone);
	}
});

const onAuthenticatedAsync = async (): Promise<void> => {
	try {
		Sentry.setUser({
			id: authenticationStore.session.ownerId.toString(),
			username: authenticationStore.session.ownerUrn,
		});

		if (authenticationStore.session.impersonateId) {
			// impersonate is contact owner is staff
			const contact = new ContactModel();
			contact.setId(authenticationStore.session.impersonateId);
			await contact.fetch();
			appStore.globalContact.setId(getIdFromUrn(contact.get('globalContact.@urn')));
		} else {
			// owner is contact
			appStore.globalContact.setId(authenticationStore.session.ownerId);
		}

		await appStore.load();

		const {
			clientPartitionUrn,
			enterpriseUrn,
			taskZoneUrn,
		} = globalFiltersStore.filters;

		await appStore.setSelectedEnterprise(enterpriseUrn || undefined);
		await appStore.setSelectedClientPartition(clientPartitionUrn || undefined);
		await appStore.setSelectedTaskZone(taskZoneUrn || undefined);
	} catch (err) {
		notificationApiError(err);
	}
};

const onLogout = () => {
	clearStores();
	Sentry.setUser({});
};

// Lorsqu'un utilisateur se connecte ou se déconnecte
reaction(
	() => authenticationStore.isAuthenticated,
	() => authenticationStore.isAuthenticated ? onAuthenticatedAsync() : onLogout(),
);

// Lorsqu'un filtre entreprise est sélectionné
reaction(
	() => appStore.enterpriseUrn,
	async () => {
		if (!appStore.enterpriseUrn) {
			// TODO: useless?
			// authenticationStore.contact.clear();
		} else {
			await appStore.loadClients();
		}
	},
);

// Lorsqu'un filtre client est sélectionné
reaction(
	() => appStore.selectedClientPartition,
	applyClientPartitionFilter,
);

// Lorsqu'un filtre taskZone est sélectionné
reaction(
	() => appStore.selectedTaskZone,
	applyTaskZoneFilter,
);

// Lorsqu'un filtre contract/devis est sélectionné
reaction(
	() => appStore.contractUrnFilter,
	applyContractUrnFilter,
);

// Lorsqu'un filtre de status de contrat est sélectionné
reaction(
	() => appStore.contractIterationStatusUrnFilter,
	applyContractIterationStatusUrnFilter,
);

// Lorsqu'un filtre de status de contrat est sélectionné
reaction(
	() => appStore.quotationStatusUrnFilter,
	applyQuotationStatusUrnFilter,
);

// Lorsqu'un filtre contact est sélectionné
reaction(
	() => appStore.contactSearchFilter,
	applyContactSearchFilter,
);

// Initialisation au premier chargement
Promise.all([
	authenticationStore.session.fetch(),
	globalFiltersStore.fetch(),
])
	.catch(() => null)
	.then(async () => {
		const refreshToken = authenticationStore.session.parentRefreshToken || authenticationStore.session.refreshToken;
		const token = authenticationStore.session.parentToken || authenticationStore.session.token;
		const exp = authenticationStore.session.parentExp || authenticationStore.session.exp;

		if (!refreshToken || !token || !exp) {
			return;
		}

		// if token of global_contact or staff_member found, we set it as the main token
		await authenticationStore.session
			.set({
				refreshToken,
				token,
			})
			.save();

		loggedConnectors.forEach(connector => {
			connector
				.setToken(token)
				.setExpiration(exp)
				.onExpired(authenticationStore.refresh);
		});

		authenticationStore.setIsAuthenticated(true);

		if (authenticationStore.session.impersonateUrn) {
			authenticationStore.setIsImpersonate(true);
		}
	})
	.catch(() => null)
	.finally(() => authenticationStore.setIsReady(true));

export default stores;
