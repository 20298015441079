import ClientPartitionModel         from 'Models/directory/ClientPartitionModel';
import EnterpriseModel              from 'Models/directory/EnterpriseModel';
import Cascader, { BaseOptionType } from 'antd/lib/cascader';
import { observer }                 from 'mobx-react';
import { computed }                 from 'mobx';
import React                        from 'react';
import {
	appStore,
}                                   from '../../stores';

const displayRender = (labels, selectedOptions) => {
	return labels.map((label, i) => {
		const option = selectedOptions[i];

		return <span key={option?.value}>
			{label}{labels.length === i + 1 ? '' : ' / '}
		</span>;
	});
};

interface ITaskZoneFilterProps {
	disabled?: boolean;
}

@observer
class TaskZoneFilter extends React.Component<ITaskZoneFilterProps> {
	public render() {
		const {
			disabled,
		} = this.props;

		if (appStore.isLoading) {
			return null;
		}

		const defaultValue: string[] = [];

		if (appStore.enterpriseUrn) {
			defaultValue.push(appStore.enterpriseUrn);
			if (appStore.clientPartitionUrn) {
				defaultValue.push(appStore.clientPartitionUrn);
				if (appStore.taskZoneUrn) {
					defaultValue.push(appStore.taskZoneUrn);
				}
			}
		}

		return (
			<div
				id="task-zone-filter"
				style={{
					minWidth: 384,
				}}
			>
				<Cascader
					allowClear={false}
					autoFocus
					changeOnSelect
					defaultValue={defaultValue}
					disabled={disabled}
					displayRender={displayRender}
					onChange={this.onChange}
					options={this.options}
					placeholder="Choisissez un site"
					size="large"
					style={{
						width: '100%',
					}}
					variant="outlined"
				/>
			</div>
		);
	}

	private getClientPartitionOptions = (enterprise: EnterpriseModel) => {
		const {
			clientPartitionCollection,
			companyCollection,
			enterprisePartitionCollection,
		} = appStore;

		const enterprisePartitions = enterprisePartitionCollection.filter(
			ep => ep.enterpriseUrn === enterprise.urn,
		);

		const clientPartitions = clientPartitionCollection.filter(
			cp => !!enterprisePartitions.find(ep => ep.urn === cp.enterprisePartitionUrn),
		);

		return clientPartitions.map(clientPartition => {
			let label = ``;

			const company = companyCollection.find(company => company.id === clientPartition.companyId);

			if (company) {
				label = `${company.name}`;
			}

			const children = this.getTaskZonesOptions(clientPartition);

			return {
				children: children,
				isLeaf: !children.length,
				label,
				value: clientPartition.urn,
			};
		});
	};

	private getTaskZonesOptions = (clientPartition: ClientPartitionModel) => {
		const {
			taskZoneAutoloadedCollection,
			taskZoneGroupCollection,
			taskZoneOwnerCollection,
		} = appStore;

		const taskZones = taskZoneAutoloadedCollection.filter(tz => {
			const owner = taskZoneOwnerCollection.find(owner => owner.id === tz.ownerId);

			return owner?.clientPartitionId === clientPartition.id;
		});

		return taskZones.map(tz => {
			let label = tz.name;

			if (tz.taskZoneGroupId) {
				const taskZoneGroup = taskZoneGroupCollection.find(
					taskZoneGroup => taskZoneGroup.id === tz.taskZoneGroupId,
				);

				if (!taskZoneGroup) {
					return;
				}

				label = taskZoneGroup.name + ' > ' + label;
			}

			return {
				label,
				value: tz.urn,
			};
		});
	};

	@computed
	private get options(): BaseOptionType[] {
		const {
			enterpriseCollection,
		} = appStore;

		if (appStore.isLoading) {
			return [];
		}

		return enterpriseCollection.map(enterprise => {
			const children = this.getClientPartitionOptions(enterprise);

			return {
				children: children,
				// disabled: !children.length,
				isLeaf: false,
				label: enterprise.name,
				value: enterprise.urn,
			};
		});
	}

	private onChange = async (values: unknown | undefined) => {
		const {
			setSelectedClientPartition,
			setSelectedEnterprise,
			setSelectedTaskZone,
		} = appStore;

		const enterpriseUrn = values ? values[0] : undefined;
		const clientPartitionUrn = values ? values[1] : undefined;
		const taskZoneUrn = values ? values[2] : undefined;

		await setSelectedEnterprise(enterpriseUrn);

		await setSelectedClientPartition(clientPartitionUrn);

		await setSelectedTaskZone(taskZoneUrn);
	};
}

export default TaskZoneFilter;