import resolvable             from 'decorators/resolvable';
import { computed }           from 'mobx';
import SalesPrivateApiModel   from '../../abstracts/SalesPrivateApiModel';
import ContractIterationModel from './ContractIterationModel';

export default class ContractIterationItemModel extends SalesPrivateApiModel {
	@resolvable(ContractIterationModel, { attributeName: 'contractIteration' })
	declare contractIteration: ContractIterationModel;

	public filters: ModelFiltersExtended<{
		contractIteration: id|string;
	}> = {};

	static get path(): string {
		return '/contract_iteration_items/{?id}';
	}

	@computed
	public get contractIterationId(): number {
		return this.get('contractIteration.id');
	}

	@computed
	public get contractIterationUrn(): number {
		return this.get('contractIteration.@urn');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}

	@computed
	public get descriptionForContract(): string {
		return this.get('descriptionForContract', '');
	}

	@computed
	public get discount(): number {
		return this.get('discount');
	}

	@computed
	public get quantity(): number {
		return this.get('quantity');
	}

	@computed
	public get publicComment(): string {
		return this.get('publicComment');
	}

	@computed
	public get title(): string {
		return this.get('title');
	}
}
