import React from 'react';

const useAwait = (callback: () => Promise<unknown>, initial:unknown = null): unknown => {
	const [value, setValue] = React.useState(initial);
	React.useEffect(() => {
		(async() => {
			setValue(await callback());
		})();
	}, []);

	return value;
};

export default useAwait;
