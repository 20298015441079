import { LockOutlined, UserOutlined } from '@ant-design/icons';
import Button                         from 'antd/lib/button';
import Form                           from 'antd/lib/form';
import Input                          from 'antd/lib/input';
import { useNavigate }                from 'react-router-dom';
import React, { useState }            from 'react';
import { authenticationStore }        from 'stores/index';
import notificationApiError           from '../utils/notificationApiError';

const LoginImpersonateForm = ({
	contactUrn,
}: {
	contactUrn: string;
}) => {
	const [loading, setLoading] = useState(false);

	const navigate = useNavigate();

	const onFinish = async values => {
		setLoading(true);

		// const { from }: any = location.state || { from: { pathname: '/home' } };
		const { from }: {
      from: {
        pathname: string;
      };
    } = { from: { pathname: '/home' } };

		try {
			await authenticationStore.impersonateLogin(
				values.username,
				values.password,
				contactUrn,
				// 'dev@wcentric.com',
				// 'Test1234',
				// '$:directory:contact:1001902',
			);

			navigate(from, { replace: true });
		} catch (err) {
			setLoading(false);

			notificationApiError(err, {
				message: 'Erreur lors de la connexion',
			});
		}
	};

	return (
		<Form
			initialValues={
				process.env.NODE_ENV === 'development' ?
					{
						email: 'dev2@wcentric.com',
						password: 'Test1234',
					}
					:
					undefined
			}
			name="login_form"
			onFinish={onFinish}
		>
			<Form.Item
				name="username"
				rules={[
					{
						message: 'Veuillez renseigner votre adresse email!',
						required: true,
					},
				]}
			>
				<Input
					placeholder="Adresse email"
					prefix={<UserOutlined />}
				/>
			</Form.Item>

			<Form.Item
				name="password"
				rules={[
					{
						message: 'Veuillez renseigner votre mot de passe!',
						required: true,
					},
				]}
			>
				<Input.Password
					placeholder="Mot de passe"
					prefix={<LockOutlined />}
				/>
			</Form.Item>

			<Button
				block={true}
				className="login-form-button"
				disabled={loading || !contactUrn}
				htmlType="submit"
				loading={loading}
				type="primary"
			>
				Connexion
			</Button>
		</Form>
	);
};

export default LoginImpersonateForm;
