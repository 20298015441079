import Typography              from 'antd/lib/typography';
import LogoComponent           from 'components/LogoComponent';
import { useNavigate }         from 'react-router-dom';
import React                   from 'react';
import { authenticationStore } from '../stores';
import './css/PublicLayout.scss';

interface PublicLayoutProps {
	children?: React.ReactNode;
	title: string;
}

const PublicLayout: React.FC<PublicLayoutProps> = props => {
	const navigate = useNavigate();

	if (authenticationStore.isAuthenticated) {
		navigate('home', { replace: true });
	}
	
	return (
		<div className="public-layout">
			<div className="public-layout-content">
				<LogoComponent />

				<Typography.Title level={1}>
					{props.title}
				</Typography.Title>

				{props.children}
			</div>
		</div>
	);
};

export default PublicLayout;