import { EllipsisOutlined }                               from '@ant-design/icons';
import FileModel                                          from 'Models/file/FileModel';
import Button                                             from 'antd/lib/button';
import Dropdown                                           from 'antd/lib/dropdown';
import { MenuItemType }                                   from 'antd/lib/menu/hooks/useItems';
import { SubMenuType }                                    from 'antd/lib/menu/hooks/useItems';
import { Page }                                           from 'components/Page';
import { observer }                                       from 'mobx-react';
import React                                              from 'react';
import { interventionsStore }                             from 'stores/index';
import AbstractInterventionList                           from '../../../entityComponents/Intervention/AbstractInterventionList';
import useAwait                                           from '../../../hooks/useAwait';
import InterventionModel                                  from '../../../modelx/models/private/intervention/InterventionModel';
import { downloadInterventionReport }                     from '../../../utils/downloadFile';
import { getInterventionReportName }                      from '../../../utils/downloadFile';
import InterventionListCompletedPageBoxMenu               from './InterventionListCompletedPageBoxMenu';

export default observer(function InterventionListCompletedPage() {
	return (
		<Page title="Interventions effectuées">
			<AbstractInterventionList
				cardProps={{
					extra: <BoxDropdownMenu />,
					title: 'Interventions effectuées',
				}}
				collection={interventionsStore.completedInterventionCollection}
				customColumns={(defaultColumns => [
					...defaultColumns,
					{
						align: 'right',
						fixed: 'right',
						render: (v, i: InterventionModel) => {
							return (
								<Dropdown menu={interventionMenu({ intervention: i })} trigger={['click']}>
									<Button style={{ border: 'none', padding: 0 }}>
										<EllipsisOutlined style={{ fontSize: 20 }} />
									</Button>
								</Dropdown>
							);
						},
						title: '',
					}
				])}
			/>
		</Page>
	);
});

const BoxDropdownMenu = observer(() => {
	return (
		<Dropdown
			menu={InterventionListCompletedPageBoxMenu()}
			trigger={['click']}
		>
			<Button style={{ padding: '0 8px' }} type="primary">
				<EllipsisOutlined style={{ fontSize: 20, verticalAlign: 'top', }} />
			</Button>
		</Dropdown>
	);
});

const interventionMenu = ({
	intervention,
}: {
	intervention: InterventionModel,
}) => {
	const {
		completedInterventionReportInterventionCollection,
		getReportCertificates,
		getReportReports,
		getReportSheets,
	} = interventionsStore;

	const reportInterventions = completedInterventionReportInterventionCollection.filter(ri => ri.interventionId === intervention.id);

	const reportCertificates = getReportCertificates(reportInterventions);
	const reportReports = getReportReports(reportInterventions);
	const reportSheets = getReportSheets(reportInterventions);

	return {
		items: [
			interventionItem({
				disabled: !reportSheets.length,
				intervention, 
				key: 'interventions',
				label: `Télécharger les fiches d'intervention`,
				reports: reportSheets,
			}),
			interventionItem({
				disabled: !reportCertificates.length,
				intervention,
				key: 'attestations',
				label: `Télécharger les attestations`,
				reports: reportCertificates,
			}),
			interventionItem({
				disabled: !reportReports.length,
				intervention,
				key: 'rapports',
				label: `Télécharger les rapports d'intervention`,
				reports: reportReports,
			}),
		],
	};
};


const interventionItem = ({ 
	disabled,
	intervention,
	key, 
	label, 
	reports,
}: MenuItemType & { intervention: InterventionModel, reports: FileModel[]}): MenuItemType | SubMenuType => {
	if (reports.length === 1) {
		return {
			disabled,
			key: key.toString(),
			label,
			onClick: () => downloadInterventionReport(intervention, reports[0]),
		};
	}
	return {
		children: reports.map((report) => ({
			key: String(report.id),
			label: <Label intervention={intervention} report={report} />,
			onClick: () => downloadInterventionReport(intervention, report),
		})),
		disabled,
		key: key.toString(),
		label,
	};
};

const Label = ({ intervention, report }) => {
	const title = useAwait(async () => {
		return await getInterventionReportName(intervention, report);
	});
	return <>{title}</>;
};