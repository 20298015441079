import Card                          from 'antd/lib/card';
import { ColumnsType }               from 'antd/lib/table/interface';
import { Page }                      from 'components/Page';
import QuotationFilter               from 'components/filters/QuotationFilter';
import QuotationActivityCategory     from 'entityComponents/Quotation/QuotationActivityCategory';
import { observer }                  from 'mobx-react';
import moment                        from 'moment';
import { Link }                      from 'react-router-dom';
import React                         from 'react';
import { formatPrice }               from 'tools/PriceHelper';
import TablePaginationCollection     from '../../components/TablePaginationCollection';
import QuotationStatusCircle         from '../../entityComponents/Quotation/QuotationStatusCircle';
import QuotationModel                from '../../modelx/models/private/sales/QuotationModel';
import { appStore, quotationsStore } from '../../stores';

const columns: ColumnsType<QuotationModel> = [
	{
		key: 'status',
		render: (_v, quotation) => <QuotationStatusCircle
			quotation={quotation}
			quotationStatusCollection={appStore.quotationStatusCollection}
		/>,
		sorter: true,
		title: 'État',
	},
	{
		key: 'number',
		render: (_v, quotation) => <Link to={`/quotations/${quotation.id}`}>{quotation.number}</Link>,
		sorter: true,
		title: 'Numéro',
	},
	{
		key: 'title',
		render: (_v, quotation) => quotation.title,
		title: 'Titre',
	},
	{
		key: 'quotation.activityCategory',
		render: (_v, quotation) => <QuotationActivityCategory
			activityCategoryCollection={quotationsStore.activityCategoryCollection}
			quotation={quotation}
		/>,
		title: 'Type',
	},
	{
		key: 'totalPriceExclTax',
		render: (_v, quotation) => formatPrice(quotation.totalPriceExclTax),
		title: 'Montant total HT',
	},
	{
		key: 'startDate',
		render: (_v, quotation) => moment(quotation.startDate).format('L'),
		sorter: true,
		title: 'Début',
	},
	{
		key: 'endDate',
		render: (_v, quotation) => moment(quotation.endDate).format('L'),
		sorter: true,
		title: 'Fin',
	},
];

function MyQuotationListPage() {
	return (
		<Page title="Mes devis">
			<div>
				<QuotationFilter />
				<Card className="card-with-table" title="Synthèse de mes devis">
					<TablePaginationCollection
						collection={quotationsStore.quotationCollection}
						columns={columns}
						rowKey={c => c.id}
					/>
				</Card>
			</div>
		</Page>
	);
}

export default observer(MyQuotationListPage);