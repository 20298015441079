import { EllipsisOutlined }                                   from '@ant-design/icons';
// import '../css/FrameworkAgreementIterationDashboard.scss';
import { DownloadOutlined }                                   from '@ant-design/icons';
import { PageHeader }                                         from '@ant-design/pro-layout';
import Col                                                    from 'antd/lib/col';
import Descriptions                                           from 'antd/lib/descriptions';
import Dropdown                                               from 'antd/lib/dropdown';
import Row                                                    from 'antd/lib/row';
import Spin                                                   from 'antd/lib/spin';
import FrameworkAgreementIterationStatusName                  from 'entityComponents/FrameworkAgreementIterationStatusName';
import { observer }                                           from 'mobx-react';
import moment                                                 from 'moment';
import { useNavigate, useParams }                             from 'react-router-dom';
import React, { useEffect, useState }                         from 'react';
import { frameworkAgreementIterationDashboardStore }          from 'stores/index';
import HttpResult                                             from '../../../components/HttpResult';
import FrameworkAgreementIterationItemListBox                 from './FrameworkAgreementIterationActivityListBox';
import FrameworkAgreementIterationOptionListBox               from './FrameworkAgreementIterationQuotationOptionListBox';

interface FrameworkAgreementIterationDashboardProps {
	backPath: string;
	children?: React.ReactNode;
	onReady: (id: number) => Promise<unknown>;
	title: string;
}

const FrameworkAgreementIterationDashboard: React.FC<FrameworkAgreementIterationDashboardProps> = props => {
	const { id } = useParams<{ id?: string | undefined; }>();
	const navigate = useNavigate();
	const [error, setError] = useState(undefined);

	const frameworkAgreementIterationId = id ? parseInt(id) : 0;

	const {
		backPath,
		children,
		onReady,
		title,
	} = props;

	const {
		frameworkAgreement,
		frameworkAgreementIteration,
		frameworkAgreementIterationFileCollection,
		frameworkAgreementIterationStatusCollection,
	} = frameworkAgreementIterationDashboardStore;

	useEffect(() => {
		onReady(frameworkAgreementIterationId).catch(e => setError(e));
	}, [id, onReady]);

	const isLoading = (
		frameworkAgreementIteration.isLoading
	);

	let frameworkAgreementName = frameworkAgreement.label;
	if (frameworkAgreement.oldNumber) {
		frameworkAgreementName += ` (${frameworkAgreement.oldNumber})`;
	}
	const frameworkAgreementIterationTitle = frameworkAgreementName + ` - version ` + frameworkAgreementIteration.number;

	const file = frameworkAgreementIterationFileCollection.first();

	return (
		<HttpResult error={error}>
			<div className="dashboard framework-agreement-iteration-dashboard">
				{isLoading ? <Spin /> : (
					<>
						<PageHeader
							extra={
								<Dropdown
									menu={{
										items: [
											{
												disabled: !file,
												key: 'download',
												label: (
													<><DownloadOutlined /> {`Télécharger l'accord signé`}</>
												),
												onClick: () => {
													file?.download();
												}
											}
										]
									}}
									trigger={['click']}
								>
									<EllipsisOutlined
										style={{ fontSize: 20, verticalAlign: 'top' }}
									/>
								</Dropdown>
							}
							onBack={() => navigate(backPath, { replace: true })}
							subTitle={
								<FrameworkAgreementIterationStatusName
									frameworkAgreementIteration={frameworkAgreementIteration}
									frameworkAgreementIterationStatusCollection={frameworkAgreementIterationStatusCollection}
								/>
							}
							title={`${title} ${frameworkAgreementIterationTitle}`}
						>
							<Descriptions column={2} size="small">
								<Descriptions.Item label="Dates">
									du&nbsp;
									{moment(frameworkAgreementIteration.startDate).format('L')}
									&nbsp;au&nbsp;
									{moment(frameworkAgreementIteration.endDate).format('L')}
								</Descriptions.Item>
							</Descriptions>
						</PageHeader>

						<Row gutter={20}>
							<Col md={9} xs={24}>
								<FrameworkAgreementIterationItemListBox
									frameworkAgreementIteration={frameworkAgreementIteration}
								/>
							</Col>

							<Col md={9} xs={24}>
								<FrameworkAgreementIterationOptionListBox
									frameworkAgreementIteration={frameworkAgreementIteration}
								/>
							</Col>

							{children}
						</Row>
					</>
				)}
			</div>
		</HttpResult>
	);
};

export default observer(FrameworkAgreementIterationDashboard);
