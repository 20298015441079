import Form         from 'antd/lib/form';
import Select       from 'antd/lib/select';
import Spin         from 'antd/lib/spin';
import { observer } from 'mobx-react';
import moment       from 'moment';
import React        from 'react';
import { appStore } from '../../stores';

function UrnFilter() {
	const {
		contractCollection,
		contractIterationCollection,
		contractUrnFilter,
		quotationCollection,
	} = appStore;

	const isLoading = (
		contractIterationCollection.isLoading
		|| contractCollection.isLoading
		|| quotationCollection.isLoading
	);

	if (isLoading) {
		return <Spin />;
	}

	return (
		<Form
			initialValues={{ urn: contractUrnFilter }}
			key={contractUrnFilter}
		>
			<Form.Item
				label="Devis / Contrat"
				name="urn"
				style={{ width: 512 }}
			>
				<Select
					loading={isLoading}
					onChange={urn => appStore.setContractUrnFilter(urn.toString())}
				>
					{contractIterationCollection.length && (
						<Select.OptGroup label="Contrats">
							{contractIterationCollection.map(contractIteration => {
								const contract = contractCollection.find(contract => contract.id === contractIteration.contractId);

								if (!contract) {
									return null;
								}

								return (
									<Select.Option
										key={contractIteration.urn}
										value={contractIteration.urn}
									>
										Contrat {contract.number} du {moment(contractIteration.startDate).format('L')} au {moment(contractIteration.endDate).format('L')}
									</Select.Option>
								);
							})}
						</Select.OptGroup>
					)}

					{quotationCollection.length && (
						<Select.OptGroup label="Devis">
							{quotationCollection.map(quotation => (
								<Select.Option
									key={quotation.urn}
									value={quotation.urn}
								>
									{quotation.number} du {moment(quotation.startDate).format('L')} au {moment(quotation.endDate).format('L')}
								</Select.Option>
							))}
						</Select.OptGroup>
					)}
				</Select>
			</Form.Item>
		</Form>
	);
}

export default observer(UrnFilter);
