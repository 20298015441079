import Layout            from 'antd/lib/layout';

import LogoComponent     from 'components/LogoComponent';
import { useLocation }   from 'react-router-dom';
import React             from 'react';
import HeaderAccountMenu from '../../components/HeaderAccountMenu';
import TaskZoneFilter    from '../TaskZoneFilter/TaskZoneFilter';
import SideBarMenu       from './SideBarMenu';
import TaskZoneContent   from './TaskZoneContent';
import '../css/PrivateLayout.scss';

const { Content, Footer, Header, Sider } = Layout;

export default function PrivateLayout({ children }) {
	let currentPath = useLocation().pathname;

	const isDashboard = currentPath.startsWith('/contracts/') ||
		currentPath.startsWith('/quotations/');

	if (currentPath.startsWith('/contracts')) {
		currentPath = '/contracts';
	} else if (currentPath.startsWith('/quotations')) {
		currentPath = '/quotations';
	}

	return (
		<Layout className="private-layout">
			<Sider
				breakpoint="lg"
				collapsible
				theme="dark"
				trigger={null}
				width={220}
			>
				<div className="logo">
					<LogoComponent style={{ height: '100%' }} />
				</div>

				<SideBarMenu currentPath={currentPath} />
			</Sider>

			<Layout id="main-layout">
				<Header className="private-layout-header" style={{ padding: '0 20px' }}>
					<div className="private-layout-header-left">
						<TaskZoneFilter disabled={isDashboard} />
					</div>

					<HeaderAccountMenu />
				</Header>

				<Content style={{ margin: 20, minHeight: 280 }}>
					<TaskZoneContent>
						{children}
					</TaskZoneContent>
				</Content>

				<Footer style={{ textAlign: 'center' }}>
					Webcentric ©2020
				</Footer>
			</Layout>
		</Layout>
	);
}
