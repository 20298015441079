import { FileTypeReference } from 'Models/file/FileModel';
import FilePrivateApiModel   from '../../abstracts/FilePrivateApiModel';

export default class FileTypeSourceModel extends FilePrivateApiModel {
	public filters: ModelFiltersExtended<{
		'locked': '0' | '1';
		'source': id;
		'source.urn': id;
		'type': id;
		'type.deprecated': '0' | '1';
		'type.reference': FileTypeReference;
		'type.service': id;
		'type.service.serviceUrn': string;
		'type.system': '0' | '1';
	}> = {};

	static get path(): string {
		return '/stonecode_file_type_sources/{?id}';
	}

	public get locked(): boolean {
		return this.get('locked');
	}

	public get sourceId() {
		return this.get('source.id');
	}

	// @doc.model(SourceModel) declare source: SourceModel;
	// @doc.model(TypeModel) declare type: TypeModel;
}
