import { observer }                     from 'mobx-react';
import { Navigate, useLocation }        from 'react-router-dom';
import React                            from 'react';
import PrivateLayout                    from '../projectComponents/PrivateLayout/PrivateLayout';
import { authenticationStore }          from '../stores';

export default observer(({ children }) => {
	const location = useLocation();

	return authenticationStore.isAuthenticated ? (
		<PrivateLayout>
			{children}
		</PrivateLayout>
	) : (
		<Navigate
			state={{ from: location }}
			to="/"
		/>
	);
});