import FileModel         from 'Models/file/FileModel';
import InterventionModel from 'Models/intervention/InterventionModel';
import TaskZoneModel     from 'Models/intervention/TaskZoneModel';

export const getInterventionReportName = async (intervention: InterventionModel, file: FileModel) => {
	const taskZone = new TaskZoneModel();

	taskZone.setId(intervention.taskZoneId);
	await taskZone.fetch();

	let fileName = `${intervention.number}_${file.createdAtMoment.format('L')}_${taskZone.taskZoneName}.pdf`;

	if (!intervention.number || !file.createdAtMoment || !taskZone.taskZoneName) {
		fileName = file.name;
	}

	return fileName;
};


export const downloadInterventionReport = async (intervention: InterventionModel, file: FileModel) => {
	const fileName = await getInterventionReportName(intervention, file);
	await file.download(fileName);
};
