const frontPartitionConfigs = [
	{
		configs: {
			PARTITION_NAME: 'QHS',
			PARTITION_URN: '$:partition:partition:1',
		},
		hostnames: [/.*espaceclientv2[-\\.]qhs[-\\.].*/i, /.*espaceclient[-\\.]qhs[-\\.].*/i, 'localhost'],
	},
	{
		configs: {
			PARTITION_NAME: 'DICOP',
			PARTITION_URN: '$:partition:partition:2',
		},
		hostnames: [/.*espaceclient[-\\.]dicop[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'HPR',
			PARTITION_URN: '$:partition:partition:3',
		},
		hostnames: [/.*espaceclient[-\\.]compagnie-hpr[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'HPRA',
			PARTITION_URN: '$:partition:partition:4',
		},
		hostnames: [/.*espaceclient[-\\.]aquitaine[-\\.]compagnie-hpr[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'CG2D',
			PARTITION_URN: '$:partition:partition:5',
		},
		hostnames: [/.*espaceclient[-\\.]cg2d[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'RCONTROLCLIM',
			PARTITION_URN: '$:partition:partition:6',
		},
		hostnames: [/.*espaceclient[-\\.]rcontrolclim[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'IGIENAIR',
			PARTITION_URN: '$:partition:partition:7',
		},
		hostnames: [/.*espaceclient[-\\.]igienair[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'ADIAMS',
			PARTITION_URN: '$:partition:partition:8',
		},
		hostnames: [/.*espaceclient[-\\.]adiams[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'AIRSN',
			PARTITION_URN: '$:partition:partition:9',
		},
		hostnames: [/.*espaceclient[-\\.]airsn[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'MAPCLIM',
			PARTITION_URN: '$:partition:partition:10',
		},
		hostnames: [/.*espaceclient[-\\.]mapclim[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'D2H',
			PARTITION_URN: '$:partition:partition:11',
		},
		hostnames: [/.*espaceclient[-\\.]d2h-ventilation[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'ZAACK',
			PARTITION_URN: '$:partition:partition:12',
		},
		hostnames: [/.*espaceclient[-\\.]zaack[-\\.].*/i],
	},
	{
		configs: {
			PARTITION_NAME: 'NANOSENSE',
			PARTITION_URN: '$:partition:partition:13',
		},
		hostnames: [/.*espaceclient[-\\.]nano-sense[-\\.].*/i],
	},
];

const getConfig = configs => {
	const config = configs.find(config => {
		return config.hostnames.find(hostname => {
			if (typeof hostname === 'string') {
				return hostname === location.hostname;
			} else if (hostname instanceof RegExp) {
				return hostname.test(location.hostname);
			} else {
				throw `Invalid hostname ${hostname}`;
			}
		});
	});

	if (!config) {
		throw `Config not found for hostname ${location.hostname}`;
	}

	return config.configs;
};

const FrontPartitionConfig = getConfig(frontPartitionConfigs);

export default FrontPartitionConfig;
