import Button          from 'antd/lib/button';
import Result          from 'antd/lib/result';
import { useNavigate } from 'react-router-dom';
import React           from 'react';

interface HttpErrorWrapperProps {
	backTo?: string
	children: JSX.Element;
	error?: Error;
}

export default function HttpResult(props: HttpErrorWrapperProps) {
	const navigate = useNavigate();

	const status = props.error?.['connectorError']?.request?.status;

	if ([403, 404, 500].includes(status)) {
		const message = props.error?.['connectorError']?.message;

		const onClick = () => navigate(props.backTo || '/home', { replace: true });

		return <Result
			extra={
				<Button
					onClick={onClick}
					type="primary"
				>
					Retour
				</Button>
			}
			status={status}
			subTitle={message}
			title={status}
		/>;
	}

	return props.children;
}