import {
	CalendarOutlined,
	CopyOutlined,
	EuroOutlined,
	MailOutlined,
	TeamOutlined,
	UserOutlined,
}                   from '@ant-design/icons';

import Menu         from 'antd/lib/menu';
import { observer } from 'mobx-react';
import { Link }     from 'react-router-dom';
import React        from 'react';
import '../css/PrivateLayout.scss';

const SideBarMenu = ({
	currentPath,
}: {
	currentPath: string,
}) => {
	return (
		<Menu
			defaultOpenKeys={['interventions', 'contacts']}
			items={[
				{
					icon: <UserOutlined />,
					key: '/home',
					label: <Link to="/home">Accueil</Link>,
				},
				{
					icon: <CopyOutlined />,
					key: '/contracts',
					label: <Link to="/contracts">Mes contrats</Link>,
				},
				{
					icon: <CopyOutlined />,
					key: '/quotations',
					label: <Link to="/quotations">Mes devis</Link>,
				},
				{
					icon: <CopyOutlined />,
					key: '/framework_agreements',
					label: <Link to="/framework_agreements">Mes accords cadres</Link>,
				},
				{
					icon: <EuroOutlined />,
					key: '/my-invoices',
					label: <Link to="/my-invoices">Mes factures</Link>,
				},
				{
					children: [
						{
							key: '/future-interventions',
							label: <Link to="/future-interventions">Prochaines interventions</Link>,
						},
						{
							key: '/completed-interventions',
							label: <Link to="/completed-interventions">Interventions effectuées</Link>,
						},
					],
					icon: <CalendarOutlined />,
					key: 'interventions',
					label: 'Interventions',
				},
				{
					children: [
						{
							key: '/contacts',
							label: <Link to="/contacts">Liste des contacts</Link>,
						},
						{
							key: '/contact-data-points',
							label: <Link to="/contact-data-points">Fonctions</Link>,
						},
					],
					icon: <TeamOutlined />,
					key: 'contacts',
					label: 'Contacts',
				},
				{
					icon: <MailOutlined />,
					key: '/contact',
					label: <Link to="/contact">Contactez-nous</Link>,
				},
			]}
			mode="inline"
			selectedKeys={currentPath ? [currentPath] : undefined}
			theme="dark"
		/>
	);
};

export default observer(SideBarMenu);
