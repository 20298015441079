import { ApiCollection }                                                       from 'Collections/ApiCollection';
import InterventionModel                                                       from 'Models/intervention/InterventionModel';
import InterventionOperationModel                                              from 'Models/intervention/InterventionOperationModel';
import ContractIterationItemEventModel                                         from 'Models/sales/ContractIterationItemEventModel';
import QuotationItemEventModel                                                 from 'Models/sales/QuotationItemEventModel';
import Skeleton                                                                from 'antd/lib/skeleton';
import { observer }                                                            from 'mobx-react';
import React, { useEffect, useState }                                          from 'react';
import { getIdFromUrn }                                                        from 'tools/UrnTools';
import { whenAsync }                                                           from 'tools/modelxTools';

export default observer((props: {
	intervention: InterventionModel;
}) => {
	const interventionOperationCollection = new ApiCollection(InterventionOperationModel);
	const [number, setNumber] = useState<string>();
	useEffect(() => {
		(async () => {
			interventionOperationCollection.clear();
			await interventionOperationCollection.listBy([props.intervention.id], 'intervention');
			if (interventionOperationCollection.models[0]) {
				await whenAsync(() => interventionOperationCollection.models[0].taskOperation.task.isLoaded);
				const sourceUrn = interventionOperationCollection.models[0].taskOperation.task.sourceUrn;
				if (sourceUrn?.includes('contract')) {
					const event = new ContractIterationItemEventModel();
					event.setId(getIdFromUrn(sourceUrn));
					await event.fetch();
					await whenAsync(() => event.contractIterationItem.contractIteration.contract.isLoaded);
					setNumber('Contrat : ' + event.contractIterationItem.contractIteration.contract.fullNumber);
				} else if (sourceUrn?.includes('quotation')) {
					const event = new QuotationItemEventModel();
					event.setId(getIdFromUrn(sourceUrn));
					await event.fetch();
					await whenAsync(() => event.quotationItem.quotation.isLoaded);
					setNumber('Devis : ' + event.quotationItem.quotation.fullNumber);
				}
				else {
					setNumber('Tâche non facturable');
				}
			}
		})();
	}, [props.intervention]);

	return typeof number === 'undefined' ? <Skeleton active paragraph={false} /> : number;
});