import { ApiCollection }                                from 'Collections/ApiCollection';
import { AutoloadedPagedCollection }                    from 'Collections/AutoloadedPagedCollection';
import ClientPartitionModel                             from 'Models/directory/ClientPartitionModel';
import GlobalContactModel                               from 'Models/directory/GlobalContactModel';
import { CONTRACT_ITERATION_STATUS_TERMINATED }         from 'constants/ContractIterationStatuses';
import {
	CONTRACT_ITERATION_STATUS_ACTIVATED,
	CONTRACT_ITERATION_STATUS_CLOSED,
	CONTRACT_ITERATION_STATUS_PRE_ACTIVATED,
	CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT,
	CONTRACT_ITERATION_STATUS_SUSPENDED,
	CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT,
}                                                       from 'constants/ContractIterationStatuses';
import { QUOTATION_STATUS_TERMINATED }                  from 'constants/QuotationStatuses';
import {
	QUOTATION_STATUS_ACTIVATED,
	QUOTATION_STATUS_CLOSED,
	QUOTATION_STATUS_PRE_ACTIVATED,
	QUOTATION_STATUS_SENT_TO_CLIENT,
	QUOTATION_STATUS_SUSPENDED,
	QUOTATION_STATUS_VALIDATED_BY_CLIENT,
}                                                       from 'constants/QuotationStatuses';
import { action, computed, makeObservable, observable } from 'mobx';
import { notificationError }                            from 'tools/notification';
import ClientModel                                      from '../modelx/models/private/directory/ClientModel';
import CompanyContactModel                              from '../modelx/models/private/directory/CompanyContactModel';
import CompanyModel                                     from '../modelx/models/private/directory/CompanyModel';
import ContactModel                                     from '../modelx/models/private/directory/ContactModel';
import EnterpriseModel                                  from '../modelx/models/private/directory/EnterpriseModel';
import EnterprisePartitionModel                         from '../modelx/models/private/directory/EnterprisePartitionModel';
import OwnerModel                                       from '../modelx/models/private/intervention/OwnerModel';
import TaskZoneGroupModel                               from '../modelx/models/private/intervention/TaskZoneGroupModel';
import TaskZoneModel                                    from '../modelx/models/private/intervention/TaskZoneModel';
import ContractIterationModel                           from '../modelx/models/private/sales/ContractIterationModel';
import ContractIterationStatusModel                     from '../modelx/models/private/sales/ContractIterationStatusModel';
import ContractModel                                    from '../modelx/models/private/sales/ContractModel';
import QuotationModel                                   from '../modelx/models/private/sales/QuotationModel';
import QuotationStatusModel                             from '../modelx/models/private/sales/QuotationStatusModel';
import { authenticationStore, globalFiltersStore }      from './index';

export default class AppStore implements Store {

	public _contactOwnCollection = new ApiCollection(ContactModel);
	public clientCollection = new ApiCollection(ClientModel);
	public clientPartitionCollection = new ApiCollection(ClientPartitionModel);
	public companyCollection = new ApiCollection(CompanyModel);
	public companyContactCollection = new ApiCollection(CompanyContactModel);
	public contact = new ContactModel();
	public contactCollection = new ApiCollection(ContactModel);
	public contractCollection = new ApiCollection(ContractModel);
	public contractIterationCollection = new ApiCollection(ContractIterationModel);
	public contractIterationStatusCollection = new ApiCollection(ContractIterationStatusModel);
	public enterpriseCollection = new ApiCollection(EnterpriseModel);
	public enterprisePartitionCollection = new ApiCollection(EnterprisePartitionModel);
	public globalContact = new GlobalContactModel();
	public quotationCollection = new ApiCollection(QuotationModel);
	public quotationStatusCollection = new ApiCollection(QuotationStatusModel);
	public taskZoneAutoloadedCollection = new AutoloadedPagedCollection(TaskZoneModel);
	public taskZoneGroupCollection = new ApiCollection(TaskZoneGroupModel);
	public taskZoneOwnerCollection = new ApiCollection(OwnerModel);

	@observable
	private _clientPartitionUrn: string | null | undefined = undefined;

	@observable
	private _contactSearchFilter: string | null | undefined = undefined;

	@observable
	private _contractIterationStatusUrnFilter: null | string[] = null;

	@observable
	private _contractUrnFilter: string | null | undefined = undefined;

	@observable
	private _enterpriseUrn: string | null | undefined = undefined;

	@observable
	private _quotationStatusUrnFilter: null | string[] = null;

	@observable
	private _taskZone: null | TaskZoneModel = null;

	@observable
	private _taskZoneUrn: string | null | undefined = undefined;

	constructor() {
		makeObservable(this);

		EnterprisePartitionModel.connector.on403(() => {
			notificationError({ message: `Vous n'avez pas les droits pour accéder aux établissements de cette entreprise.` });
		});
	}

	public clear() {
		this._contractUrnFilter = null;
		this._contractIterationStatusUrnFilter = null;
		this._quotationStatusUrnFilter = null;

		this.clientCollection.clear();
		this.clientPartitionCollection.clear();
		this.companyCollection.clear();
		this.companyContactCollection.clear();
		this.contact.clear();
		this.contactCollection.clear();
		this.contractCollection.clear();
		this.contractIterationCollection.clear();
		this.enterpriseCollection.clear();
		this.enterprisePartitionCollection.clear();
		this.globalContact.clear();
		this.quotationCollection.clear();
		this.taskZoneAutoloadedCollection.clear();
		this.taskZoneGroupCollection.clear();
		this.taskZoneOwnerCollection.clear();
		this._contactOwnCollection.clear();

		this.contractIterationStatusCollection.clear();
		this.quotationStatusCollection.clear();
	}

	public load = async () => {
		try {
			await this.globalContact.fetch();
		} catch (err) {
			notificationError({ message: `Impossible d'accéder à l'interface.`, });
			authenticationStore.logout();
			return;
		}

		await this._contactOwnCollection
			.setFilter('globalContact', this.globalContact.id)
			.list();

		await this.enterprisePartitionCollection
			.listByFromCollection(this._contactOwnCollection, 'id', 'contacts');

		await this.enterpriseCollection
			.listByFromCollection(this.enterprisePartitionCollection, 'id', 'enterprisePartitions');
	};

	public loadClients = async () => {
		if (!this.selectedEnterprisePartition) {
			console.warn('no selectedEnterprisePartition');
			return;
		}

		const contact = this._contactOwnCollection.findBy('enterprisePartitionId', this.selectedEnterprisePartition.id);

		if (!contact) {
			console.warn('no contact');
			return;
		}

		await authenticationStore.contactLogin(contact.urn);

		await Promise.all([
			this.clientCollection
				.listByFromCollection(this.enterprisePartitionCollection, 'id', 'clientPartition.enterprisePartition'),

			this.clientPartitionCollection
				.listByFromCollection(this.enterprisePartitionCollection, 'id', 'enterprisePartition'),
		]);

		await Promise.all([
			this.companyCollection
				.listByFromCollection(this.clientPartitionCollection, 'companyId', 'id')
				.then(async () => {
					await this.companyContactCollection
						.listByFromCollection(this.companyCollection, 'id', 'company');
				}),

			this.contactCollection
				.setSort('lastName')
				.listByFromCollection(this.enterprisePartitionCollection, 'id', 'enterprisePartition'),

			this.taskZoneAutoloadedCollection
				.listByFromCollection(this.clientCollection, 'urn', 'owner.clientUrn')
				.then(async () => {
				}),

			this.taskZoneGroupCollection
				.listByFromCollection(this.clientCollection, 'urn', 'taskZone.owner.clientUrn'),

			this.taskZoneOwnerCollection
				.listByFromCollection(this.clientCollection, 'urn', 'clientUrn'),
		]);

		await Promise.all([
			this.contractIterationStatusCollection.list({ cache: 3600, }),

			this.quotationStatusCollection.list({ cache: 3600, }),
		]);
	};

	@action
	public setContactSearchFilter = async (value: string | null) => {
			await globalFiltersStore.filters
				.setContactSearch(value)
				.save();

			this._contactSearchFilter = value;
		};

	@action
	public setContractIterationStatusUrnFilter = (urns: string[]) => {
			globalFiltersStore.filters.setContractIterationStatusUrn(urns).save();
			this._contractIterationStatusUrnFilter = urns;
		};

	@action
	public setContractUrnFilter = async (urn: string | null) => {
			await globalFiltersStore.filters
				.setContractUrn(urn)
				.save();

			this._contractUrnFilter = urn;
		};

	@action
	public setSelectedClientPartition = async (clientPartitionUrn: string | undefined) => {
			await globalFiltersStore.filters
				.setClientPartitionUrn(clientPartitionUrn)
				.save();

			this._clientPartitionUrn = clientPartitionUrn;
		};

	@action
	public setQuotationStatusUrnFilter = (urns: string[]) => {
			globalFiltersStore.filters.setQuotationStatusUrn(urns).save();
			this._quotationStatusUrnFilter = urns;
		};

	@action
	public setSelectedEnterprise = async (enterpriseUrn: string | undefined) => {
			await globalFiltersStore.filters
				.setEnterpriseUrn(enterpriseUrn)
				.save();

			this._enterpriseUrn = enterpriseUrn;
		};

	@action
	public setSelectedTaskZone = async (taskZoneUrn: string | undefined) => {
			await globalFiltersStore.filters
				.setTaskZoneUrn(taskZoneUrn)
				.save();

			this._taskZoneUrn = taskZoneUrn;
		};

	@action
	public setTaskZoneFilterAndFetch(taskZone: TaskZoneModel) {
		this.contractIterationCollection
			.setSort('createdAt', false)
			.setFilters({
				archived: false,
				'contractIterationStatus.reference': [
					CONTRACT_ITERATION_STATUS_ACTIVATED.reference,
					CONTRACT_ITERATION_STATUS_CLOSED.reference,
					CONTRACT_ITERATION_STATUS_PRE_ACTIVATED.reference,
					CONTRACT_ITERATION_STATUS_SENT_TO_CLIENT.reference,
					CONTRACT_ITERATION_STATUS_SUSPENDED.reference,
					CONTRACT_ITERATION_STATUS_TERMINATED.reference,
					CONTRACT_ITERATION_STATUS_VALIDATED_BY_CLIENT.reference,
				],
				'items.itemActivities.locationUrn': taskZone.urn,
			});

		this.quotationCollection
			.setSort('createdAt', false)
			.setFilters({
				archived: false,
				'items.itemActivities.locationUrn': taskZone.urn,
				'quotationStatus.reference': [
					QUOTATION_STATUS_ACTIVATED.reference,
					QUOTATION_STATUS_CLOSED.reference,
					QUOTATION_STATUS_PRE_ACTIVATED.reference,
					QUOTATION_STATUS_SENT_TO_CLIENT.reference,
					QUOTATION_STATUS_SUSPENDED.reference,
					QUOTATION_STATUS_TERMINATED.reference,
					QUOTATION_STATUS_VALIDATED_BY_CLIENT.reference,
				],
			});

		const contractPromise = this.contractIterationCollection.list().then(() => {
			return this.contractCollection.listByFromCollection(this.contractIterationCollection, 'contractId', 'id');
		});

		const quotationPromise = this.quotationCollection.list();

		return Promise
			.all([
				contractPromise,
				quotationPromise,
			])
			.then(() => {
				this.updateContractUrnFilter();
				return true;
			});
	}

	public updateContractUrnFilter() {
		const contractIteration = this.contractIterationCollection.find(contractIteration => {
			return contractIteration.urn === globalFiltersStore.filters.contractUrn;
		});

		const quotation = this.quotationCollection.find(quotation => {
			return quotation.urn === globalFiltersStore.filters.contractUrn;
		});

		if (contractIteration) {
			this.setContractUrnFilter(contractIteration.urn);
		} else if (quotation) {
			this.setContractUrnFilter(quotation.urn);
		} else {
			const firstContractIteration = this.contractIterationCollection.first();
			const firstQuotation = this.quotationCollection.first();
			if (firstContractIteration) {
				globalFiltersStore.filters.setContractUrn(firstContractIteration.urn);
				globalFiltersStore.filters.save();
				this.setContractUrnFilter(firstContractIteration.urn);
			} else if (firstQuotation) {
				globalFiltersStore.filters.setContractUrn(firstQuotation.urn);
				globalFiltersStore.filters.save();
				this.setContractUrnFilter(firstQuotation.urn);
			}
		}
	}

	@computed
	public get isLoading(): boolean {
		return (
			this.contact.isLoading ||
			this.contractCollection.isLoading ||
			this.contractIterationCollection.isLoading ||
			this.enterprisePartitionCollection.isLoading ||
			this.globalContact.isLoading ||
			this.quotationCollection.isLoading ||
			this.taskZoneAutoloadedCollection.realIsLoading ||
			this._contactOwnCollection.isLoading
		);
	}

	@computed
	public get contactSearchFilter() {
		return this._contactSearchFilter;
	}

	@computed
	public get contractIterationStatusUrnFilter() {
		return this._contractIterationStatusUrnFilter;
	}

	@computed
	public get contractUrnFilter() {
		return this._contractUrnFilter;
	}

	@computed
	public get clientPartitionUrn() {
		return this._clientPartitionUrn;
	}

	@computed
	public get enterpriseUrn() {
		return this._enterpriseUrn;
	}

	@computed
	public get taskZoneUrn() {
		return this._taskZoneUrn;
	}

	@computed
	public get quotationStatusUrnFilter() {
		return this._quotationStatusUrnFilter;
	}

	@computed
	public get selectedClient() {
		return this.clientCollection.find(c => c.clientPartitionId === this.selectedClientPartition?.id);
	}

	@computed
	public get selectedClientPartition() {
		return this.clientPartitionCollection.find(cp => cp.urn === this.clientPartitionUrn);
	}

	@computed
	public get selectedEnterprise() {
		return this.enterpriseCollection.find(e => e.urn === this.enterpriseUrn);
	}

	@computed
	public get selectedEnterprisePartition() {
		return this.enterprisePartitionCollection.find(ep => ep.get('enterprise.@urn') === this.enterpriseUrn);
	}

	@computed
	public get selectedTaskZone() {
		return this.taskZoneAutoloadedCollection.find(tz => tz.urn === this.taskZoneUrn);
	}
}
