import resolvable           from 'decorators/resolvable';
import { computed }         from 'mobx';
import SalesPrivateApiModel from '../../abstracts/SalesPrivateApiModel';
import QuotationModel       from './QuotationModel';

export default class QuotationItemModel extends SalesPrivateApiModel {

	public filters: ModelFiltersExtended<{ quotation: id }> = {};
	
	@resolvable(QuotationModel, { attributeName: 'quotation' })
	declare quotation: QuotationModel;

	static get path(): string {
		return '/quotation_items/{?id}';
	}

	@computed
	public get quotationId(): number {
		return this.get('quotation.id');
	}

	@computed
	public get description(): string {
		return this.get('description', '');
	}

	@computed
	public get descriptionForContract(): string {
		return this.get('descriptionForContract', '');
	}

	@computed
	public get discount(): number {
		return this.get('discount');
	}

	@computed
	public get publicComment(): string {
		return this.get('publicComment');
	}

	@computed
	public get quantity(): number {
		return this.get('quantity');
	}

	@computed
	public get title(): string {
		return this.get('title');
	}
}
